import type { AsyncDataOptions } from "#app";

import type { v1, ExtractFromAPI } from "@netgame/openapi";

import useAsyncFetch from "./useAsyncFetch";
import useIsGuest from "./useIsGuest";

type DepositStreakResponse = Required<ExtractFromAPI<v1.paths, "/rest/deposit-streak/info/", "get">>;

const defaultData = {
	maxNumberOfDeposit: 8,
	currentCountDeposit: 0,
	finishedAt: "",
	expiredAt: "",
	resetStreakAt: ""
};

const useGetDepositStreakData = ({
	immediate = true,
	cached = true,
	...restOptions
}: AsyncDataOptions<DepositStreakResponse> & { cached?: true } = {}) => {
	const isGuest = useIsGuest();

	return useAsyncFetch({
		path: "/rest/deposit-streak/info/",
		method: "get",
		options: {
			cached: !cached ? undefined : true,
			server: restOptions?.server,
			immediate: immediate && !isGuest.value,
			watch: [isGuest, ...(restOptions?.watch || [])],
			default: () => defaultData,
			transform: (data) => {
				const progress = data.success
					? (Array(data.maxNumberOfDeposit).fill(false).fill(true, 0, data.currentCountDeposit) as boolean[])
					: Array(8).fill(true);
				const canWheel = data.success && data.currentCountDeposit === data.maxNumberOfDeposit;
				return {
					...data,
					progress,
					canWheel
				};
			}
		}
	});
};

export default useGetDepositStreakData;
